
const interviewSlide = (() => {
  const pageInterview = (document.getElementById("pageInterview") != null);

  if (pageInterview) {
    let sW = window.innerWidth;
    let setSlidesPerView;
    let setSpace;

    if (sW > 750) {
      setSlidesPerView = 1.5
      setSpace = 30
    } else {
      setSlidesPerView = 1.3
      setSpace = 15
    }



    //スライダー
    const swiper = new Swiper(".swiper", {
      loop: true,
      speed: 400,
      // effect: 'fade',
      slidesPerView: setSlidesPerView, // 一度に表示する枚数
      spaceBetween: setSpace, // スライド間の距離
      centeredSlides: true, // アクティブなスライドを中央にする
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      // thumbs: {
      //   swiper: sliderThumbnail
      // },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }

})();
export default interviewSlide;