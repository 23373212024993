// const pageTop = (document.getElementById("pageTop") != null);
export { default as userAgent } from './userAgent';
export { default as windowResize } from './windowResize';
export { default as scrollControlFunc } from './scrollControlFunc';
export { default as scrollAnchorFunc } from './scrollAnchorFunc';
export { default as loadImg } from './loadImg';
export { default as navFunc } from './navFunc';
export { default as scrollHeader } from './scrollHeader';

export { default as elemFade } from './elemFade';
export { default as elemSlideIn } from './elemSlideIn';

export { default as topMain } from './topMain';
// export { default as topTopicsSlide } from './topTopicsSlide';

export { default as lifeinfoTab } from './lifeinfoTab';

export { default as beforeAfterImg } from './beforeAfterImg';
export { default as iconSwipePlan } from './iconSwipePlan';
export { default as planSlide } from './planSlide';
export { default as planSlide2 } from './planSlide2';

export { default as iconSwipe } from './iconSwipe';

export { default as locationMap } from './locationMap';

export { default as limitedPlanModal } from './limitedPlanModal';

export { default as gallerySlide } from './gallerySlide';

export { default as setagayaAccordion } from './setagayaAccordion';

export { default as decidingSlide } from './decidingSlide';
export { default as decidingTab } from './decidingTab';
export { default as decidingAccordion } from './decidingAccordion';
export { default as interviewSlide } from './interviewSlide';

export { default as mapModal } from './mapModal';
export { default as mapImgPosition } from './mapImgPosition';


// export { default as loadImgSample } from './loadImgSample';
export { default as loadFunc } from './loadFunc';