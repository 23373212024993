import { scrollControlFunc, windowResize, topMain, } from './index';

const loadFunc = (() => {

  let loadWrap = document.getElementById('jsLoadWrap');
  scrollControlFunc.noScroll();
  windowResize();

  const startFunc = () => {
    loadWrap.classList.add('no-act');
    scrollControlFunc.returnScroll();
  }

  document.addEventListener("DOMContentLoaded", () => {
    setTimeout(startFunc, 0)
    topMain()
  });

})();
export default loadFunc;