const iconSwipe = (() => {
  const bodyLocation = (document.getElementById("pageLocation") != null);
  const bodyPlan = (document.getElementById("pagePlan") != null);
  const bodyAccess = (document.getElementById("pageAccess") != null);
  const bodyLandscape = (document.getElementById("pageLandscape") != null);
  const bodyInterview = (document.getElementById("pageInterview") != null);
  const bodyIot = (document.getElementById("pageIot") != null);
  const bodyMap = (document.getElementById("pageMap") != null);


  if (bodyLocation || bodyPlan || bodyAccess || bodyLandscape || bodyInterview || bodyIot || bodyMap) {

    const iconSwipe = document.querySelectorAll('.js-iconSwipe');

    if (iconSwipe.length > 0) {

      gsap.set(".js-iconSwipe", {
        opacity: 1,
        x: 100,
      });


      for (let i = 0; i < iconSwipe.length; i++) {
        gsap.to(iconSwipe[i], {
          x: -20,
          repeat: 1,
          duration: 1.5,
          scrollTrigger: {
            trigger: iconSwipe[i],
            start: 'top center',
            end: 'bottom top',
            // markers: true,
          }
        });

        gsap.to(iconSwipe[i], {
          delay: 3,
          opacity: 0,
          zIndex: -1,
          scrollTrigger: {
            trigger: iconSwipe[i],
          }
        });


      }

    }

  }

})();

export default iconSwipe;