const scrollHeader = (() => {

  const header = document.getElementById('jsHeader');
  const navBtn = document.getElementById('jsNavBtn');
  let sW = window.innerWidth;


  if (!(sW > 750)) {
    let scrollPoint = 0; // 現在のスクロール位置をセットする変数
    let lastPoint = 0; // 1つ前のスクロール位置をセットする変数

    window.addEventListener("scroll", function () {

      scrollPoint = window.scrollY;
      console.log(lastPoint);

      if (scrollPoint > lastPoint) {
        header.classList.add('none');
        navBtn.classList.add('none');
        // console.log(下);
      } else {
        header.classList.remove('none');
        navBtn.classList.remove('none');
        // console.log(上);
      }

      if (scrollPoint < 10) {
        header.classList.remove('none');
        navBtn.classList.remove('none');
      }

      lastPoint = scrollPoint;
    });


  }


})();
export default scrollHeader;