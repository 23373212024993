
const gallerySlide = (() => {
  const bodyGallery = (document.getElementById("pageGallery") != null);

  if (bodyGallery) {
    $(function() {
      $('.slide-box').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>',
        fade: true,
        asNavFor: '.slide-nav'
      });  
      $('.slide-nav').slick({
        slidesToShow: 6,
        asNavFor: '.slide-box',
        // dots: true,
        focusOnSelect: true,
      }); 
    });

  }





})();
export default gallerySlide;