import { decidingSlide } from './index';

const decidingTab = (() => {
  const pageDeciding = (document.getElementById("pageDeciding") != null);

  if (pageDeciding) {
    let btn = document.querySelectorAll('.js-tab-btn');
    let cntnt = document.querySelectorAll('.js-tab-cnt');
    let btn2 = document.querySelector('.js-tab-btn-2');
    let flexArea2 = document.querySelector('.js-flex-area-2');
    // console.log(btn);

    for (let i = 0; i < btn.length; i++) {
      btn[i].addEventListener("click", () => {

        // 押されたiとjが同じだったらactとらない
        for (let j = 0; j < btn.length; j++) {
          if (!(j == i)) {
            // console.log(j);
            btn[j].classList.remove("act");
            cntnt[j].classList.remove("act");
          }
        }

        btn[i].classList.add('act');
        cntnt[i].classList.add('act');

        if (btn2.classList.contains('act')) {
          flexArea2.classList.add('change')
        } else {
          flexArea2.classList.remove('change')
        }
      });
    }


    let hlag = 0;
    btn2.addEventListener("click", () => {
      hlag++;
      if (hlag === 1) {
        decidingSlide()
      }
    });

  }





})();
export default decidingTab;