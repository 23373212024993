const lifeinfoTab = (() => {
  const bodyLocation = (document.getElementById("pageLocation") != null);
  const bodyLandscape = (document.getElementById("pageLandscape") != null);
  if (bodyLocation) {

    const tabBtn = document.querySelectorAll('.tabBtn');
    const tabCntnt = document.querySelectorAll('.tabCntnt');

    tabBtn[0].classList.add('tabAct');
    tabCntnt[0].classList.add('tabAct');
    tabCntnt[0].classList.add('show');

    let count = 0;

    for (let i = 0; i < tabBtn.length; i++) {
      tabBtn[i].addEventListener('click', (event) => {

        for (let j = 0; j < tabBtn.length; j++) {
          tabBtn[j].classList.remove('tabAct');
          tabCntnt[j].classList.remove('tabAct');
          setTimeout(function(){
            tabCntnt[j].classList.remove('show');
          }, 100);
        }

        tabBtn[i].classList.add('tabAct');        
        tabCntnt[i].classList.add('tabAct');
        setTimeout(function(){
          tabCntnt[i].classList.add('show');
        }, 110);
    

      });
    }

  }


  if (bodyLandscape) {

    const tabBtn = document.querySelectorAll('.tabBtn');
    const tabCntnt = document.querySelectorAll('.tabCntnt');
    let sW = window.innerWidth;

    tabBtn[0].classList.add('tabAct');
    tabCntnt[0].classList.add('tabAct');

    let count = 0;

    for (let i = 0; i < tabBtn.length; i++) {
      tabBtn[i].addEventListener('click', (event) => {

        for (let j = 0; j < tabBtn.length; j++) {
          tabBtn[j].classList.remove('tabAct');
          tabCntnt[j].classList.remove('tabAct');
        }

        tabBtn[i].classList.add('tabAct');
        tabCntnt[i].classList.add('tabAct');
      });
    }


    if (!(sW > 750)) {

      let landImg = document.getElementById('landscapeImg');

    
      for (let i = 0; i < tabBtn.length; i++) {
        tabBtn[i].addEventListener('click', (event) => {
          let target;

          if (i == 0){
            target = 50;
          } else if (i == 1){
            target = 60;
          } else if (i == 2){
            target = 150;
          } else if (i == 3){
            target = 80;
          } else if (i == 4){
            target = 140;
          } else if (i == 5){
            target = 0;
          } else if (i == 6){
            target = 80;
          } else if (i == 7){
            target = 340;
          }


          console.log(target);

          landImg.scrollTo({
            left: target,
            behavior: 'smooth',
          });
        });
      }
    }


  }
})();
export default lifeinfoTab;