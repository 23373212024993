const elemSlideIn = (() => {


  let jsSlideTxt = document.querySelectorAll('.js-slide-txt');
  let jsLeadBox = document.querySelectorAll('.js-lead-box');  //発火トリガー

  if (jsLeadBox.length > 0) {


    gsap.set(
      jsSlideTxt, { width: "0%", }
    )

    const txtSlide = (i) => {
      let leadChid = jsLeadBox[i].querySelectorAll('.js-slide-txt');

      gsap.to(leadChid, {
        width: "100%",
        duration: 1,
        stagger: 0.2,
      })
    }

    for (let i = 0; i < jsLeadBox.length; i++) {
      ScrollTrigger.create({
        trigger: jsLeadBox[i],
        start: "top 70%",
        onEnter: () => {
          txtSlide(i)
        },
        once: true,
        // markers: true,
      });
    }
  }


  // ---------------------------------------------------

  // let jsBgIn = document.querySelectorAll('.js-bg-in');

  // if (jsBgIn.length > 0) {

    // for (let i = 0; i < jsBgIn.length; i++) {
    //   let bgChid = jsBgIn[i].querySelectorAll('.js-slide-bg');

    //   ScrollTrigger.create({
    //     trigger: jsBgIn[i],
    //     start: "top center",
    //     toggleClass: { targets: bgChid, className: "act" },
    //     stagger: 0.2,
    //     once: true,
    //     // markers: true,
    //   });
    // }

    // let bgChid = jsBgIn[0].querySelectorAll('.js-slide-bg');
    // batch(bgChid, {
    //   interval: 0.1, // time window (in seconds) for batching to occur. The first callback that occurs (of its type) will start the timer, and when it elapses, any other similar callbacks for other targets will be batched into an array and fed to the callback. Default is 0.1
    //   batchMax: 3,   // maximum batch size (targets)
    //   onEnter: batch => gsap.to(batch, { 
    //     toggleClass: { targets: bgChid, className: "act" }, 
    //   }),
    //   // you can also define things like start, end, etc.
    // });

  //   ScrollTrigger.batch(bgChid, {
  //     trigger: jsBgIn,
  //     start: "top center",
  //     onEnter: batch => gsap.to(batch, {
  //       toggleClass: { targets: bgChid, className: "act" },
  //       stagger: 0.2,
  //       markers: true,
  //     }),
  //   });
  // }





  
  let jsBgIn = document.querySelectorAll('.js-bg-in');
  if (jsBgIn.length > 0) {
    for (let i = 0; i < jsBgIn.length; i++) {

      let bgChid = jsBgIn[i].querySelectorAll('.js-slide-bg');
      
      ScrollTrigger.batch(bgChid, {
        trigger: jsBgIn[i],
        start: "top center",
        toggleClass: { targets: bgChid, className: "act" }, //クラスをつけたり、外したりできる
        once: true,
        // stagger: 0.2,
        // markers: true,
      });

    }
  }
  
})();



export default elemSlideIn;