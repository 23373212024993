// import { beforeAfterImg, iconSwipePlan } from './index';

// const planSlide = (() => {
//   const bodyPlan = (document.getElementById("pagePlan") != null);
//   const pageLimitedPlan = (document.getElementById("pageLimitedPlan") != null);


//   if (pageLimitedPlan) {
//     let sW = window.innerWidth;

//     const tabBtn = document.querySelectorAll('.js-tabBtn');
//     const tabCntnt = document.querySelectorAll('.js-tabCntnt');
//     const C1tabBtn = document.querySelectorAll('.js-c1-tab');
//     const C1MenuCntnt = document.querySelectorAll('.js-c1-cntnt');
//     const btnB1Back = document.getElementById('js-B1btnBack');
//     const btnB1Next = document.getElementById('js-B1btnNext');
//     const btnC1Back = document.getElementById('js-C1btnBack');
//     const btnC1Next = document.getElementById('js-C1btnNext');
//     const btnC1menu1Back = document.getElementById('js-C1-1btnBack');
//     const btnC1menu1Next = document.getElementById('js-C1-1btnNext');
//     const btnC1menu2Back = document.getElementById('js-C1-2btnBack');
//     const btnC1menu2Next = document.getElementById('js-C1-2btnNext');
//     const btnE2Back = document.getElementById('js-E2btnBack');
//     const btnE2Next = document.getElementById('js-E2btnNext');
//     const btnI3Back = document.getElementById('js-I3btnBack');
//     const btnI3Next = document.getElementById('js-I3btnNext');
//     const btnJBack = document.getElementById('js-JbtnBack');
//     const btnJNext = document.getElementById('js-JbtnNext');


//     tabBtn[0].classList.add('tabAct');
//     tabCntnt[0].classList.add('tabAct');

//     for (let i = 0; i < tabBtn.length; i++) {
//       tabBtn[i].addEventListener('click', (event) => {

//         if (tabBtn[i].classList.contains('tabAct')) {
//           tabBtn[i].classList.remove('tabAct');
//           tabCntnt[i].classList.remove('tabAct');
//         } else {
//           for (let j = 0; j < tabBtn.length; j++) {
//             tabBtn[j].classList.remove('tabAct');
//             tabCntnt[j].classList.remove('tabAct');
//           }
//           tabBtn[i].classList.add('tabAct');
//           tabCntnt[i].classList.add('tabAct');
//           slick.slick('setPosition');
//           beforeAfterImg()
//         }
//       });
//     }



//     if (sW > 750) {
//       btnB1Back.addEventListener('click', (event) => {
//         tabBtn[0].classList.remove('tabAct');
//         tabCntnt[0].classList.remove('tabAct');
//         tabBtn[4].classList.add('tabAct');
//         tabCntnt[4].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnB1Next.addEventListener('click', (event) => {
//         tabBtn[0].classList.remove('tabAct');
//         tabCntnt[0].classList.remove('tabAct');
//         tabBtn[1].classList.add('tabAct');
//         tabCntnt[1].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });

//       btnC1Back.addEventListener('click', (event) => {
//         tabBtn[1].classList.remove('tabAct');
//         tabCntnt[1].classList.remove('tabAct');
//         tabBtn[0].classList.add('tabAct');
//         tabCntnt[0].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnC1Next.addEventListener('click', (event) => {
//         tabBtn[1].classList.remove('tabAct');
//         tabCntnt[1].classList.remove('tabAct');
//         tabBtn[2].classList.add('tabAct');
//         tabCntnt[2].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });

//       btnE2Back.addEventListener('click', (event) => {
//         tabBtn[2].classList.remove('tabAct');
//         tabCntnt[2].classList.remove('tabAct');
//         tabBtn[1].classList.add('tabAct');
//         tabCntnt[1].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnE2Next.addEventListener('click', (event) => {
//         tabBtn[2].classList.remove('tabAct');
//         tabCntnt[2].classList.remove('tabAct');
//         tabBtn[3].classList.add('tabAct');
//         tabCntnt[3].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });

//       btnI3Back.addEventListener('click', (event) => {
//         tabBtn[3].classList.remove('tabAct');
//         tabCntnt[3].classList.remove('tabAct');
//         tabBtn[2].classList.add('tabAct');
//         tabCntnt[2].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnI3Next.addEventListener('click', (event) => {
//         tabBtn[3].classList.remove('tabAct');
//         tabCntnt[3].classList.remove('tabAct');
//         tabBtn[4].classList.add('tabAct');
//         tabCntnt[4].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });

//       btnJBack.addEventListener('click', (event) => {
//         tabBtn[4].classList.remove('tabAct');
//         tabCntnt[4].classList.remove('tabAct');
//         tabBtn[3].classList.add('tabAct');
//         tabCntnt[3].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnJNext.addEventListener('click', (event) => {
//         tabBtn[4].classList.remove('tabAct');
//         tabCntnt[4].classList.remove('tabAct');
//         tabBtn[0].classList.add('tabAct');
//         tabCntnt[0].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//     }




//     if (!(sW > 750)) {
//       btnB1Back.addEventListener('click', (event) => {
//         tabBtn[0].classList.remove('tabAct');
//         tabCntnt[0].classList.remove('tabAct');
//         tabBtn[4].classList.add('tabAct');
//         tabCntnt[4].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnB1Next.addEventListener('click', (event) => {
//         tabBtn[0].classList.remove('tabAct');
//         tabCntnt[0].classList.remove('tabAct');
//         tabBtn[1].classList.add('tabAct');
//         tabCntnt[1].classList.add('tabAct');
//         C1MenuCntnt[0].classList.add('tabAct');
//         C1tabBtn[0].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });

//       btnC1Back.addEventListener('click', (event) => {
//         tabBtn[1].classList.remove('tabAct');
//         tabCntnt[1].classList.remove('tabAct');
//         C1MenuCntnt[0].classList.remove('tabAct');
//         C1tabBtn[0].classList.remove('tabAct');
//         tabBtn[0].classList.add('tabAct');
//         tabCntnt[0].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnC1Next.addEventListener('click', (event) => {
//         C1tabBtn[0].classList.remove('tabAct');
//         C1MenuCntnt[0].classList.remove('tabAct');
//         C1tabBtn[1].classList.add('tabAct');
//         C1MenuCntnt[1].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });

//       btnC1menu1Back.addEventListener('click', (event) => {
//         C1tabBtn[1].classList.remove('tabAct');
//         C1MenuCntnt[1].classList.remove('tabAct');
//         C1tabBtn[0].classList.add('tabAct');
//         C1MenuCntnt[0].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnC1menu1Next.addEventListener('click', (event) => {
//         C1tabBtn[1].classList.remove('tabAct');
//         C1MenuCntnt[1].classList.remove('tabAct');
//         C1tabBtn[2].classList.add('tabAct');
//         C1MenuCntnt[2].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });

//       btnC1menu2Back.addEventListener('click', (event) => {
//         C1tabBtn[2].classList.remove('tabAct');
//         C1MenuCntnt[2].classList.remove('tabAct');
//         C1tabBtn[1].classList.add('tabAct');
//         C1MenuCntnt[1].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnC1menu2Next.addEventListener('click', (event) => {
//         tabBtn[1].classList.remove('tabAct');
//         C1tabBtn[2].classList.remove('tabAct');
//         C1MenuCntnt[2].classList.remove('tabAct');
//         tabCntnt[1].classList.remove('tabAct');
//         tabBtn[2].classList.add('tabAct');
//         tabCntnt[2].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });

//       btnE2Back.addEventListener('click', (event) => {
//         tabBtn[2].classList.remove('tabAct');
//         tabCntnt[2].classList.remove('tabAct');
//         tabBtn[1].classList.add('tabAct');
//         tabCntnt[1].classList.add('tabAct');
//         C1tabBtn[2].classList.add('tabAct');
//         C1MenuCntnt[2].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnE2Next.addEventListener('click', (event) => {
//         tabBtn[2].classList.remove('tabAct');
//         tabCntnt[2].classList.remove('tabAct');
//         tabBtn[3].classList.add('tabAct');
//         tabCntnt[3].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });

//       btnI3Back.addEventListener('click', (event) => {
//         tabBtn[3].classList.remove('tabAct');
//         tabCntnt[3].classList.remove('tabAct');
//         tabBtn[2].classList.add('tabAct');
//         tabCntnt[2].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnI3Next.addEventListener('click', (event) => {
//         tabBtn[3].classList.remove('tabAct');
//         tabCntnt[3].classList.remove('tabAct');
//         tabBtn[4].classList.add('tabAct');
//         tabCntnt[4].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });

//       btnJBack.addEventListener('click', (event) => {
//         tabBtn[4].classList.remove('tabAct');
//         tabCntnt[4].classList.remove('tabAct');
//         tabBtn[3].classList.add('tabAct');
//         tabCntnt[3].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });
//       btnJNext.addEventListener('click', (event) => {
//         tabBtn[4].classList.remove('tabAct');
//         tabCntnt[4].classList.remove('tabAct');
//         tabBtn[0].classList.add('tabAct');
//         tabCntnt[0].classList.add('tabAct');
//         slick.slick('setPosition');
//         beforeAfterImg()
//       });




//       //限定のC１タイプのメニュータブ
//       C1tabBtn[0].classList.add('tabAct');
//       C1MenuCntnt[0].classList.add('tabAct');


//       for (let i = 0; i < C1tabBtn.length; i++) {
//         C1tabBtn[i].addEventListener('click', (event) => {

//           for (let j = 0; j < C1tabBtn.length; j++) {
//             C1tabBtn[j].classList.remove('tabAct');
//             C1MenuCntnt[j].classList.remove('tabAct');
//           }

//           C1tabBtn[i].classList.add('tabAct');
//           C1MenuCntnt[i].classList.add('tabAct');

//         });
//       }
//     }




//     beforeAfterImg()

//     var slick = $('.slide-wrap').slick({
//       infinite: true,
//       touchMove: false,
//       touchThreshold: false,
//       draggable: false,
//       dots: true,
//       prevArrow: '<div class="slick-arrow_prev"></div>',
//       nextArrow: '<div class="slick-arrow_next"></div>',
//     });


//     let iconSwipeCount = 0;
//     iconSwipePlan(0)
//     slick.on('afterChange', function (event, slick, currentSlide) {
//       if (currentSlide == 3) {
//         // console.log("ppppp");
//         // console.log(iconSwipeCount);
//         if (iconSwipeCount == 0) {
//           iconSwipePlan(1)
//         }
//         iconSwipeCount = iconSwipeCount + 1
//       }
//     });


//     $('.js-slideBtn1').on('click', function () {
//       slick.slick('slickGoTo', 0, false);
//       slick.slick('setPosition');
//     });
//     $('.js-slideBtn2').on('click', function () {
//       slick.slick('slickGoTo', 1, false);
//     });
//     $('.js-slideBtn3').on('click', function () {
//       slick.slick('slickGoTo', 2, false);
//     });
//     $('.js-slideBtn4').on('click', function () {
//       slick.slick('slickGoTo', 3, false);
//     });

//   }






//   if (bodyPlan) {

//     const tabBtn = document.querySelectorAll('.js-tabBtn');
//     const tabCntnt = document.querySelectorAll('.js-tabCntnt');
//     const Numbtn = document.querySelectorAll('.js-slideBtn');
//     const btnBack = document.getElementById('js-back');
//     const btnNext = document.getElementById('js-next');
//     const btnCBack = document.getElementById('js-CbtnBack');
//     const btnCNext = document.getElementById('js-CbtnNext');
//     const btnABack = document.getElementById('js-AbtnBack');
//     const btnANext = document.getElementById('js-AbtnNext');
//     const btnB1Back = document.getElementById('js-B1btnBack');
//     const btnB1Next = document.getElementById('js-B1btnNext');
//     const btnB2rBack = document.getElementById('js-B2rbtnBack');
//     const btnB2rNext = document.getElementById('js-B2rbtnNext');
//     const btnB1gBack = document.getElementById('js-B1gbtnBack');
//     const btnB1gNext = document.getElementById('js-B1gbtnNext');
//     const btnF1Back = document.getElementById('js-F1btnBack');
//     const btnF1Next = document.getElementById('js-F1btnNext');
//     const btnG2Back = document.getElementById('js-G2btnBack');
//     const btnG2Next = document.getElementById('js-G2btnNext');

//     // console.log(btnA);

//     tabBtn[0].classList.add('tabAct');
//     tabCntnt[0].classList.add('tabAct');

//     let count = 0;

//     for (let i = 0; i < tabBtn.length; i++) {
//       tabBtn[i].addEventListener('click', (event) => {

//         // var wallDoore = $(".walldoor_img").innerHeight();
//         // $('.walldoor_img').css('height',wallDoore+'px');

//         if (tabBtn[i].classList.contains('tabAct')) {
//           tabBtn[i].classList.remove('tabAct');
//           tabCntnt[i].classList.remove('tabAct');
//         } else {
//           for (let j = 0; j < tabBtn.length; j++) {
//             tabBtn[j].classList.remove('tabAct');
//             tabCntnt[j].classList.remove('tabAct');
//           }
//           tabBtn[i].classList.add('tabAct');
//           tabCntnt[i].classList.add('tabAct');
//           slick.slick('setPosition');
//           beforeAfterImg()
//         }

//       });
//     }


//     btnABack.addEventListener('click', (event) => {
//       tabBtn[0].classList.remove('tabAct');
//       tabCntnt[0].classList.remove('tabAct');
//       tabBtn[5].classList.add('tabAct');
//       tabCntnt[5].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });
//     btnANext.addEventListener('click', (event) => {
//       tabBtn[0].classList.remove('tabAct');
//       tabCntnt[0].classList.remove('tabAct');
//       tabBtn[1].classList.add('tabAct');
//       tabCntnt[1].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });

//     btnB1Back.addEventListener('click', (event) => {
//       tabBtn[1].classList.remove('tabAct');
//       tabCntnt[1].classList.remove('tabAct');
//       tabBtn[0].classList.add('tabAct');
//       tabCntnt[0].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });
//     btnB1Next.addEventListener('click', (event) => {
//       tabBtn[1].classList.remove('tabAct');
//       tabCntnt[1].classList.remove('tabAct');
//       tabBtn[2].classList.add('tabAct');
//       tabCntnt[2].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });

//     btnB2rBack.addEventListener('click', (event) => {
//       tabBtn[2].classList.remove('tabAct');
//       tabCntnt[2].classList.remove('tabAct');
//       tabBtn[1].classList.add('tabAct');
//       tabCntnt[1].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });
//     btnB2rNext.addEventListener('click', (event) => {
//       tabBtn[2].classList.remove('tabAct');
//       tabCntnt[2].classList.remove('tabAct');
//       tabBtn[3].classList.add('tabAct');
//       tabCntnt[3].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });

//     btnCBack.addEventListener('click', (event) => {
//       tabBtn[3].classList.remove('tabAct');
//       tabCntnt[3].classList.remove('tabAct');
//       tabBtn[2].classList.add('tabAct');
//       tabCntnt[2].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });
//     btnCNext.addEventListener('click', (event) => {
//       tabBtn[3].classList.remove('tabAct');
//       tabCntnt[3].classList.remove('tabAct');
//       tabBtn[4].classList.add('tabAct');
//       tabCntnt[4].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });

//     btnF1Back.addEventListener('click', (event) => {
//       tabBtn[4].classList.remove('tabAct');
//       tabCntnt[4].classList.remove('tabAct');
//       tabBtn[3].classList.add('tabAct');
//       tabCntnt[3].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });
//     btnF1Next.addEventListener('click', (event) => {
//       tabBtn[4].classList.remove('tabAct');
//       tabCntnt[4].classList.remove('tabAct');
//       tabBtn[5].classList.add('tabAct');
//       tabCntnt[5].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });

//     btnG2Back.addEventListener('click', (event) => {
//       tabBtn[5].classList.remove('tabAct');
//       tabCntnt[5].classList.remove('tabAct');
//       tabBtn[4].classList.add('tabAct');
//       tabCntnt[4].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });
//     btnG2Next.addEventListener('click', (event) => {
//       tabBtn[5].classList.remove('tabAct');
//       tabCntnt[5].classList.remove('tabAct');
//       tabBtn[0].classList.add('tabAct');
//       tabCntnt[0].classList.add('tabAct');
//       slick.slick('setPosition');
//       beforeAfterImg()
//     });


//     beforeAfterImg()


//     var slick = $('.slide-wrap').slick({
//       infinite: true,
//       touchMove: false,
//       touchThreshold: false,
//       draggable: false,
//       dots: true,
//       prevArrow: '<div class="slick-arrow_prev"></div>',
//       nextArrow: '<div class="slick-arrow_next"></div>',
//     });

//     let iconSwipeCount = 0;
//     iconSwipePlan(0)
//     slick.on('afterChange', function (event, slick, currentSlide) {
//       if (currentSlide == 3) {
//         // console.log("ppppp");
//         // console.log(iconSwipeCount);
//         if (iconSwipeCount == 0) {
//           iconSwipePlan(1)
//         }
//         iconSwipeCount = iconSwipeCount + 1
//       }
//     });

//     $('.js-slideBtn1').on('click', function () {
//       // console.log("a");
//       slick.slick('slickGoTo', 0, false);
//       slick.slick('setPosition');
//     });
//     $('.js-slideBtn2').on('click', function () {
//       slick.slick('slickGoTo', 1, false);
//     });
//     $('.js-slideBtn3').on('click', function () {
//       slick.slick('slickGoTo', 2, false);
//     });
//     $('.js-slideBtn4').on('click', function () {
//       slick.slick('slickGoTo', 3, false);
//     });


//     //     ====================================
//     //     console.log(tabCntnt)
//     //     const btnBack2 = document.getElementById('js-back2');
//     //     const btnNext2 = document.getElementById('js-next2');


//     //     btnNext2.addEventListener('click', (event) => {
//     //       console.log(tabCntnt)

//     //       let num
//     //       let numNext
//     //       tabCntnt.forEach((element, index) => {
//     //         if (element.classList.contains("tabAct")) {
//     //           console.log('存在します')
//     //           num = index
//     //           numNext = num + 1

//     //           if (num == tabCntnt.length - 1) {
//     //             console.log("4番目");
//     //             numNext = 0
//     //             console.log(num);
//     //           }
//     //         }
//     //       });

//     //       tabCntnt[num].classList.remove('tabAct');
//     //       tabCntnt[numNext].classList.add('tabAct');
//     //       console.log(num);
//     //       console.log(numNext);

//     //     });


//     //     // console.log(tabCntnt.length);

//     // ===========================================


//   }
// })();
// export default planSlide;