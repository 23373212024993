const mapModal = (() => {
  const pageMap = (document.getElementById("pageMap") != null);

  if (pageMap) {

    MicroModal.init({
      onClose: () => {
        youtubePause();
      }
    });

    const youtubePause = () => {
      videoControl("pauseVideo");
      function videoControl(action) {
        var $playerWindow = $("#jsYoutube")[0].contentWindow;
        $playerWindow.postMessage(
          '{"event":"command","func":"' + action + '","args":""}',
          "*"
        );
      }
    }

  }

})();

export default mapModal;