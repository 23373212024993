
const setagayaAccordion = (() => {
  const pageSetagaya = (document.getElementById("pageSetagaya") != null);

  if (pageSetagaya) {
    // console.log("object");
    let btn = document.querySelectorAll('.js-btn');
    let accoArea = document.querySelectorAll('.js-acco');
    let txtInner = document.querySelectorAll('.js-txt-inner');
    let txtBox = document.querySelectorAll('.js-txt-box');


    btn.forEach((element, index) => {
      element.addEventListener('click', (event) => {
        let innerHight = txtInner[index].clientHeight;

        if (accoArea[index].classList.contains('act') == true) {
          accoArea[index].classList.remove('act');
          txtBox[index].style.height = 0 + 'px';
        } else {
          accoArea[index].classList.add('act');
          txtBox[index].style.height = innerHight + 'px';
        }

      });
    });
  }





})();
export default setagayaAccordion;