const mapImgPosition = (() => {
  const pageMap = (document.getElementById("pageMap") != null);

  if (pageMap) {

    let sW = window.innerWidth;
    let mapImg = document.querySelector('.js-map-img');

    if (!(sW > 750)) {
      mapImg.scrollTo({
        left: 200,
      });

    }
  }

})();

export default mapImgPosition;