const loadImg = (elem) => {

  let img = document.querySelectorAll(elem);
  let imgLength = img.length;
  let count = 0;

  return new Promise(resolve => {
    for (let i = 0; i < imgLength; i++) {
      img[i].addEventListener('load', () => {
        count = count + (i + 1);
        if (count == 1 / 2 * imgLength * (imgLength + 1)) {
          resolve();
          console.log('loadImgEnd');
        }
      });
      img[i].src = img[i].getAttribute('data-src');
    }
  });

}
export default loadImg;