const topMain = () => {
  const bodyTop = (document.getElementById("pageTop") != null);

  if (bodyTop) {

    let mainArea1 = document.querySelector('.js-main-area-1');
    let area1Img2 = document.querySelector('.js-main-1-2');
    let area1Txt = document.querySelector('.js-main-1-txt');
    let area1Movie = document.querySelector('.js-main-movie');
    let area1Movie2 = document.querySelector('.js-main-movie-2');
    let area1Movie3 = document.querySelector('.js-main-movie-3');

    let mainArea2 = document.querySelector('.js-main-area-2');
    let area2Txt = document.querySelector('.js-main-2-txt');

    // let mainArea3 = document.querySelector('.js-main-area-3');
    // let area3Img2 = document.querySelector('.js-main-3-2');
    // let area3Txt1 = document.querySelector('.js-main-3-1-txt');
    // let area3Txt2 = document.querySelector('.js-main-3-2-txt');
    // let sW = window.innerWidth;

    let mainArea4 = document.querySelector('.js-main-area-4');
    let area4Txt = document.querySelector('.js-main-4-txt');



    // エリア１
    const topMainArea1 = () => {
      setTimeout(() => {
        area1Movie.classList.add('act')
        area1Movie2.classList.add('act')
        area1Movie3.classList.add('act')
      }, 400);
      setTimeout(() => {
        area1Img2.classList.add('act')
      }, 800);
      setTimeout(() => {
        area1Txt.classList.add('act')
      }, 1200);
    }


    // エリア2
    const topMainArea2 = () => {
      setTimeout(() => {
        area2Txt.classList.add('act')
      }, 400);
    }


    // エリア3
    // const topMainArea3 = () => {
    //   setTimeout(() => {
    //     area3Img2.classList.add('act')
    //   }, 500);
    //   setTimeout(() => {
    //     area3Txt1.classList.add('act')
    //   }, 800);
    //   setTimeout(() => {
    //     area3Txt2.classList.add('act')
    //   }, 1100);
    // }


    // エリア4
    const topMainArea4 = () => {
      setTimeout(() => {
        area4Txt.classList.add('act')
      }, 800);
    }




    const swiper = new Swiper(".swiper", {
      // loop: true,
      speed: 1500,
      effect: 'fade',
      slidesPerView: 1, // 一度に表示する枚数
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
      // thumbs: {
      //   swiper: sliderThumbnail
      // },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      on: {
        slideChangeTransitionEnd: function () {
          if (swiper.realIndex == 0) {
            // console.log('1枚目のスライド');
            topMainArea4()
          }
          if (swiper.realIndex == 1) {
            // console.log('2枚目のスライド');
            topMainArea2()
          }
          if (swiper.realIndex == 2) {
            // console.log('3枚目のスライド');
            topMainArea1()
          }
        },
      },
    });
    
    if (swiper.realIndex == 0) {
      // console.log('1枚目のスライド');
      topMainArea4()
    }

  }

}

export default topMain;