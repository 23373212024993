import { beforeAfterImg, iconSwipePlan } from './index';

const planSlide2 = (() => {
  const pageType = (document.getElementById("pageType") != null);
  if (pageType) {

    // console.log("aaa");

    var slick = $('.slide-wrap').slick({
      infinite: true,
      touchMove: false,
      touchThreshold: false,
      draggable: false,
      dots: true,
      prevArrow: '<div class="slick-arrow_prev"></div>',
      nextArrow: '<div class="slick-arrow_next"></div>',
    });

    beforeAfterImg();

    let iconSwipeCount = 0;
    iconSwipePlan(0)
    slick.on('afterChange', function (event, slick, currentSlide) {
      if (currentSlide == 3) {
        if (iconSwipeCount == 0) {
          iconSwipePlan(1)
        }
        iconSwipeCount = iconSwipeCount + 1
      }
    });

    $('.js-slideBtn1').on('click', function () {
      slick.slick('slickGoTo', 0, false);
      slick.slick('setPosition');
    });
    $('.js-slideBtn2').on('click', function () {
      slick.slick('slickGoTo', 1, false);
    });
    $('.js-slideBtn3').on('click', function () {
      slick.slick('slickGoTo', 2, false);
    });
    $('.js-slideBtn4').on('click', function () {
      slick.slick('slickGoTo', 3, false);
    });



    const secType = document.querySelector('.sec-type');
    const btnC1Next = document.getElementById('js-C1SpbtnNext');
    const btnC1menu1Back = document.getElementById('js-C1-1btnBack');
    const btnC1menu1Next = document.getElementById('js-C1-1btnNext');
    const btnC1menu2Back = document.getElementById('js-C1-2btnBack');


    if (secType.classList.contains("js-sec-c1")) {
      // console.log("ある");
      //  限定のC１タイプのメニュータブ˝
      const C1tabBtn = document.querySelectorAll('.js-c1-tab');
      const C1MenuCntnt = document.querySelectorAll('.js-c1-cntnt');

      C1tabBtn[0].classList.add('tabAct');
      C1MenuCntnt[0].classList.add('tabAct');


      for (let i = 0; i < C1tabBtn.length; i++) {
        C1tabBtn[i].addEventListener('click', (event) => {

          for (let j = 0; j < C1tabBtn.length; j++) {
            C1tabBtn[j].classList.remove('tabAct');
            C1MenuCntnt[j].classList.remove('tabAct');
          }

          C1tabBtn[i].classList.add('tabAct');
          C1MenuCntnt[i].classList.add('tabAct');

        });
      }


      // console.log(btnC1Next);
      btnC1Next.addEventListener('click', (event) => {
        C1MenuCntnt[0].classList.remove('tabAct');
        C1tabBtn[0].classList.remove('tabAct');
        C1MenuCntnt[1].classList.add('tabAct');
        C1tabBtn[1].classList.add('tabAct');
      });

      btnC1menu1Back.addEventListener('click', (event) => {
        C1MenuCntnt[1].classList.remove('tabAct');
        C1tabBtn[1].classList.remove('tabAct');
        C1MenuCntnt[0].classList.add('tabAct');
        C1tabBtn[0].classList.add('tabAct');
      });
      btnC1menu1Next.addEventListener('click', (event) => {
        C1MenuCntnt[1].classList.remove('tabAct');
        C1tabBtn[1].classList.remove('tabAct');
        C1MenuCntnt[2].classList.add('tabAct');
        C1tabBtn[2].classList.add('tabAct');
      });

      btnC1menu2Back.addEventListener('click', (event) => {
        C1MenuCntnt[2].classList.remove('tabAct');
        C1tabBtn[2].classList.remove('tabAct');
        C1MenuCntnt[1].classList.add('tabAct');
        C1tabBtn[1].classList.add('tabAct');
      });

    }

  }

})();
export default planSlide2;