const iconSwipePlan = (num) => {
  const pageType = (document.getElementById("pageType") != null);
  if (pageType) {

    const iconSwipe2 = document.querySelectorAll('.js-iconSwipe2');

    if (iconSwipe2.length > 0) {

      if (num == 0) {
        gsap.set(".js-iconSwipe2", {
          opacity: 1,
          zIndex: 1,
          left: "50%",
        });
      }


      if (num == 1) {

        for (let i = 0; i < iconSwipe2.length; i++) {
          gsap.to(iconSwipe2[i], {
            left: "30%",
            repeat: 1,
            duration: 1.5,
            scrollTrigger: {
              trigger: iconSwipe2[i],
              start: 'top center',
              end: 'bottom top',
              // markers: true,
            }
          });

          gsap.to(iconSwipe2[i], {
            delay: 3,
            opacity: 0,
            zIndex: -1,
            scrollTrigger: {
              trigger: iconSwipe2[i],
            }
          });
        }

      }

    }

  }

};

export default iconSwipePlan;