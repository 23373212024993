const elemFade = (() => {
  const jsElemFade = document.querySelectorAll('.js-elem-fade');
  if (jsElemFade.length > 0) {

    for (let i = 0; i < jsElemFade.length; i++) {
      ScrollTrigger.create({
        trigger: jsElemFade[i],
        start: 'top 90%',
        end: 'bottom top',
        toggleClass: { targets: jsElemFade[i], className: "act" },
        once: true,
        // markers: true
      })
    }

  }
})();

export default elemFade;