
const decidingAccordion = (() => {
  const pageDeciding = (document.getElementById("pageDeciding") != null);

  if (pageDeciding) {
    // console.log("object");
    let area = document.querySelectorAll('.js-aco-area');
    let inner = document.querySelectorAll('.js-aco-inner');
    let openBtn = document.querySelectorAll('.js-aco-open');
    let closeBtn = document.querySelectorAll('.js-aco-close');
    

    openBtn.forEach((element, index) => {
      element.addEventListener('click', (event) => {
        let innerHight = inner[index].clientHeight;

        if (area[index].classList.contains('act') == true) {
          area[index].classList.remove('act');
          openBtn[index].classList.remove('act');
          area[index].style.height = 10 + 'rem';
        } else {
          area[index].classList.add('act');
          openBtn[index].classList.add('act');
          area[index].style.height = innerHight + 'px';
        }
      });
    });


    closeBtn.forEach((element, index) => {
      element.addEventListener('click', (event) => {
        if (area[index].classList.contains('act') == true) {
          area[index].classList.remove('act');
          openBtn[index].classList.remove('act');
          area[index].style.height = 10 + 'rem';
        }
      });
    });
  }





})();
export default decidingAccordion;