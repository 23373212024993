const beforeAfterImg = () => {
  const pageType = (document.getElementById("pageType") != null);
  if (pageType) {


    // var imgBeforeAfter = $(window).load(function(){
      $(".walldoor_img").twentytwenty({
            no_overlay: true, 
      });
    // });
    // $(function(){
    //   $(".walldoor_img").twentytwenty({
    //     no_overlay: true, //hover時の表示消す
    //   });
    // });




  }
};
export default beforeAfterImg;