const limitedPlanModal = (() => {
  const pageLimitedPlan = (document.getElementById("pageLimitedPlan") != null);
  const pageTop = (document.getElementById("pageTop") != null);

  if (pageLimitedPlan || pageTop) {

    MicroModal.init({
      onClose: () => {
        youtubePause();
      }
    });

    const youtubePause = () => {
      const jsYtube = document.querySelectorAll('.jsYoutube');

      videoControl("pauseVideo");
      function videoControl(action) {

        for (let i = 0; i < jsYtube.length; i++) {
          var $playerWindow = $(jsYtube[i])[0].contentWindow;
          $playerWindow.postMessage(
            '{"event":"command","func":"' + action + '","args":""}',
            "*"
          );
        }
      }
    }

  }

})();

export default limitedPlanModal;